import React from 'react'
import './loader.css'


function Loader(){



    return(


<div className="site-loader">
<div class="sloader">
<div class="sloader-square"></div>
<div class="sloader-square"></div>
<div class="sloader-square"></div>
<div class="sloader-square"></div>
<div class="sloader-square"></div>
<div class="sloader-square"></div>
<div class="sloader-square"></div>
</div>
</div>

    )
}

export default Loader